/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.rect {
	text-align: center;
	font-size: 30px;
	padding-top: 10px;
	height: 100%;
}

.demo-light {
	background: rgba(245, 229, 166, 0.5)
}

.demo-dark {
/*	background: rgba(148, 215, 199, 0.5);*/
}

	.demo-dark.header {
/*		background: rgba(243, 158, 108, 0.5);*/
	}

	.demo-dark.footer {
		background: rgba(123, 155, 207, 0.5);
	}

.small {
	height: 50px;
	border: 1px solid lightgray;
}

.study-counts-card {
	border: 1px solid #ccc;
	padding: 16px;
	margin: 16px;
}

.dashboard-card h1,
.dashboard-card h2,
.dashboard-card h3,
.dashboard-card h4,
.dashboard-card h5,
.dashboard-card h6 {
	text-align: center;
}

.demo-container {
	display: flex;
	justify-content: center;
	height: 450px;
}

#app {
	align-self: center;
}

.dx-datagrid .dx-command-edit {
	display: flex;
	align-items: center;
}

	.dx-datagrid .dx-command-edit .dx-button {
		margin-right: 8px; /* Adjust spacing as needed */
	}

.buttons-entry-crs {
	width: 800px;
	align-self: center;
}

.buttons-column > .column-header {
	flex-grow: 0;
	width: 300px;
	height: 35px;
	font-size: 130%;
	opacity: 0.6;
	text-align: left;
	padding-left: 15px;
}

.buttons {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

	.buttons > div {
		width: 200px;
		flex-wrap: nowrap;
		display: flex;
	}

.buttons-column > div {
	width: 200px;
	height: 50px;
	text-align: center;
	padding: 0 0.5rem;
}

.buttons-column {
	width: 200px;
	justify-content: center;
}
.offline {
	display: none;
}

.online {
	display: block;
}

.options {
	padding: 20px;
	margin-top: 20px;
	background-color: rgba(191, 191, 191, 0.15);
}

.caption {
	font-size: 18px;
	font-weight: 500;
}

.option {
	margin-top: 10px;
}

.cb-tooltip-text-wrap {
    white-space: normal;
    word-wrap: break-word;
}
