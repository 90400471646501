.summaryPanel {
    position: fixed;
    z-index: 1;
    right: 10px;
    background: #eee;
    overflow-x: hidden;
    padding: 8px 0;
    overflow: auto;
    max-height: 80vh;
    border-radius: 8px 8px 8px 8px;
}

.panelContent {
    padding: 5px;
}

.panelOpened {
    width: 20vw;
    padding-left: 5px;
}
.panelClosed {
    width: min-content;
    padding: 10px;
}
.showContent {
    display: block;
}
.hideContent {
    display: none;
}

.summaryPanel p {
    text-decoration: none;
    font-size: 11px;
    color: #2196f3;
    display: block;
    margin-bottom: 4px;
}

.summaryPanel .editIcon {
    color: #2196f3;
}

.summaryPanel .dx-list-item {
    border-top: none;
    font-size: 11px;
}

.summaryPanel .dx-list-item .assetName {
    color: black;
}

.summaryPanel .dx-list-item-selected .assetName {
    outline: 1px solid blue;
    padding: 1px;
    background-color: blue;
    color: white!important;
}

.summaryPanel .dx-list-item .description {
    color: grey;
    font-style: italic;
}

.unsynced {
    color: red;
}

.syncButton {
    margin-left: 25px;
}