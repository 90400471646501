/* Container for the cards */
.study-counts-card-container {
	display: flex;
	justify-content: space-around;
	align-items: center;
	margin: 16px;
}

/* Individual card styling */
.study-count-card {
	background-color: #fff;
	border: 1px solid #ddd;
	border-radius: 8px;
	padding: 24px;
	text-align: center;
	flex: 1; 
	margin: 8px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Large number styling */
.count-number {
	font-size: 48px;
	font-weight: bold;
	color: #333;
}

/* Label below the number */
.count-label {
	font-size: 18px;
	color: #777;
	margin-top: 8px;
}
