/* Add margin between rows */
.dashboard-row {
	margin-bottom: 30px;
}

.dashboard-card {
	background-color: #fff;
	border: 1px solid #ddd;
	border-radius: 8px;
	padding: 24px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
